<script setup lang="ts">
import { useI18n, computed, useColorMode } from '#imports'

const colorMode = useColorMode()
const { $t } = useI18n()

const isLight = computed({
  get() {
    return colorMode.value === 'light'
  },
  set() {
    colorMode.preference = colorMode.value === 'dark' ? 'light' : 'dark'
  },
})
</script>

<template>
  <label class="cursor-pointer flex items-center space-x-2 w-full">
    <UToggle
      v-model="isLight"
      onIcon="i-heroicons-sun-solid"
      offIcon="i-heroicons-moon-solid"
    />

    <span>{{ $t('lightTheme') }}</span>
  </label>
</template>
